var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{},[_c('div',{staticClass:"flex flex-col w-full text-gray-500"},[_c('div',[_c('ul',{staticClass:"flex flex-col flex-grow py-6 space-y-1",class:{ 'disabled': _vm.disabled }},[_c('li',{staticClass:"flex-grow border-purple-500 cursor-pointer hover:bg-gray-100",class:{
            'border-l-4 bg-gray-100 border-purple-500 text-purple-500': _vm.isCurrentRoute('/home'),
            'disabled': _vm.disabled,
          },attrs:{"id":"home"}},[_c('a',{on:{"click":function($event){return _vm.navigate('/home')}}},[_c('span',{staticClass:"flex items-center p-2 space-x-2",class:_vm.collapsed ? 'justify-center' : ''},[_c('i',{staticClass:"text-xl bx bxs-dashboard"}),(!_vm.collapsed)?_c('span',{staticClass:"font-semibold"},[_vm._v("Inicio")]):_vm._e()])])]),(!_vm.collapsed)?_c('li',{attrs:{"id":"sub-title"}},[_c('span',{staticClass:"flex px-4 my-2 mt-4 text-sm font-bold text-gray-400 uppercase"},[_vm._v("Mi taller")])]):_vm._e(),_vm._l((_vm.menu),function(item){return _c('li',{key:item.routePath,staticClass:"flex-grow border-purple-500 hover:bg-gray-100",class:{
            'border-l-4 bg-gray-100 border-purple-500': _vm.isCurrentRoute(item.routePath),
            'disabled': _vm.disabled,
          }},[_c('a',{staticClass:"w-full flex justify-between pr-2 items-center",class:{ 'disabled': _vm.disabled },on:{"click":function($event){return _vm.navigate(item.routePath)}}},[_c('span',{staticClass:"flex items-center p-2 space-x-2",class:_vm.collapsed ? 'justify-center' : ''},[_c('i',{staticClass:"text-xl",class:item.iconClass}),(!_vm.collapsed)?_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(item.label))]):_vm._e()]),(item.isNew)?_c('span',{staticClass:"new-feature-icon"},[_vm._v("New")]):_vm._e()])])}),(_vm.collapsed && _vm.$store.state.account.accountType === 1)?_c('li',{staticClass:"flex-grow border-purple-500 cursor-pointer hover:bg-gray-100 hover:text-purple-500 fixed w-16 bottom-4",class:{ 'disabled': _vm.disabled }},[_vm._m(0)]):_vm._e()],2)]),(!_vm.collapsed && _vm.$store.state.account.accountType === 1)?_c('div',{staticClass:"fixed w-64 bottom-4"},[_c('div',{staticClass:"flex items-center cursor-pointer",on:{"click":function($event){return _vm.navigate({ name: 'admin.index', params: { slot: 'users-and-permissions' } })}}},[_c('span',{staticClass:"w-full flex items-center p-2 space-x-2 hover:bg-gray-100 hover:text-purple-500",class:{ 'justify-center': _vm.collapsed, 'disabled': _vm.disabled }},[_c('i',{staticClass:"text-xl bx bx-mail-send"}),_c('span',{staticClass:"font-bold",attrs:{"id":"team-invite"}},[_vm._v("Invitar a mi equipo")])])])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"flex items-center p-2 space-x-2 justify-center"},[_c('i',{staticClass:"text-xl bx bx-mail-send"})])
}]

export { render, staticRenderFns }