<template>
  <div class="bg-white border-b border-gray-200 shadow">
    <div class="flex flex-wrap items-center px-4 py-2">
      <!-- logo -->
      <div class="mr-4">
        <sa-icon-button
          with-bg
          iconClass="text-xl bx bx-menu"
          @click.stop="onMenuClick"
        />
      </div>
      <div
        @click="$router.push({ to: '/home' })"
        class="flex flex-row flex-wrap items-center space-x-1 no-underline"
      >
        <img
          src="@/assets/img/mini-responsive-samii.png"
          v-if="!logo"
          alt=""
          width="20px"
          class="m-1"
        />
        <img :src="logo" v-if="logo" alt="" width="20px" class="m-1" />
        <span class="font-bold text-gray-600 text-md font-headline">{{
          $store.state.business.name || "Fixme up"
        }}</span>
      </div>

      <div class="flex items-center justify-center flex-grow">
        <div class="w-4/5 sm:w-4/5 md:w-2/3 lg:w-3/5 xl:w-1/3">
          <el-input
            v-model="search"
            class="border rounded-md"
            @focus="
              search = '';
              orders = [];
              clients = [];
            "
            @input="querySearchAsync"
            placeholder="Buscar en Órdenes, Clientes, IMEI"
          >
          </el-input>
          <div
            class=""
            v-if="orders.length > 0 || clients.length > 0"
            style="
              position: fixed;
              z-index: 2;
              background-color: white;
              padding: 10px;
              width: 300px;
              border-color: rgb(139, 92, 246);
              border-width: 1px;
            "
          >
            <div style="max-height: 500px; overflow-y: scroll">
              <div>
                <h3><strong>Clientes</strong></h3>
                <div class="ml-3 pb-2 pt-2" v-if="clients.length === 0">
                  No hay resultados.
                </div>
                <div
                  v-for="client in clients"
                  v-bind:key="client.id"
                  class="ml-3 pb-2 pt-2 cursor-pointer"
                >
                  <a @click="handleSelectSearch(client, 'client')">{{
                    client?.fullName
                  }}</a>
                </div>
              </div>
              <div>
                <h3><strong>Ordenes</strong></h3>
                <div class="ml-3 pb-2 pt-2" v-if="orders.length === 0">
                  No hay resultados.
                </div>
                <div
                  v-for="order in orders"
                  v-bind:key="order.id"
                  class="ml-3 pb-2 pt-2 cursor-pointer"
                >
                  <a @click="handleSelectSearch(order, 'order')">{{
                    order.reference
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- menu -->
      <ul class="flex flex-row items-center flex-shrink ml-auto">
        <quick-start-tip
          title="Acciones Rapidas"
          content="Con este boton tendras acceso rapido a las acciones
          mas comunes que puedes hacer en SAMII"
          placement="bottom"
          :bg-purple="$store.state.quickStart.step === 1"
          :rounded-full="$store.state.quickStart.step === 1"
          :value="$store.state.quickStart.step === 1"
          @onNextStep="handleNextStep">
          <li v-if="!$store.state.account.membershipLimitReached">
            <el-dropdown trigger="click">
              <sa-icon-button
                id="quick-actions-menu"
                :iconClass="`text-4xl bx bx-plus-circle ${$store.state.quickStart.step === 1 ? 'text-white' : 'text-purple-500'}`"
              />
              <el-dropdown-menu slot="dropdown">
                <quick-start-tip
                  title="Crear Orden"
                  content="Da click en la opcion 'Crear orden' para acceder de manera rapida
                  al formulario de las ordenes"
                  placement="left-start"
                  :bg-purple="$store.state.quickStart.step === 2"
                  :value="$store.state.quickStart.step === 2"
                  @onNextStep="handleNextStep">
                  <el-dropdown-item>
                    <el-button
                      class="text-gray-700"
                      :class="{ 'hover:text-purple-500': $store.state.quickStart.step === 0 }"
                      type="text"
                      :disabled="$store.state.quickStart.step !== 0 || !$store.state.account.canCreateOrders"
                      @click="$router.push({ name: 'orders.create' })">
                      <span class="flex items-center" :class="{ 'text-white': $store.state.quickStart.step === 2 }">
                        <i class="text-xl bx bx-task"></i>
                        Crear Orden
                      </span>
                    </el-button>
                  </el-dropdown-item>
                </quick-start-tip>
                <el-dropdown-item>
                  <el-button
                    type="text"
                    class="text-gray-700 hover:text-purple-500"
                    :disabled="$store.state.quickStart.step !== 0"
                    @click="$router.push({ name: 'products.create' })"
                  >
                  <span class="flex items-center">
                    <i class="text-xl bx bxs-package"></i>
                    Agregar Producto
                  </span>
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button
                    type="text"
                    class="text-gray-700 hover:text-purple-500"
                    :disabled="$store.state.quickStart.step !== 0"
                    @click="$router.push({ name: 'clients.create' })"
                  >
                  <span class="flex items-center">
                    <i class="text-xl bx bx-user"></i>
                    Crear Cliente
                  </span>
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button
                    type="text"
                    class="text-gray-700 hover:text-purple-500"
                    :disabled="$store.state.quickStart.step !== 0"
                    @click="$router.push({ name: 'suppliers.create' })"
                  >
                  <span class="flex items-center">
                    <i class="text-xl bx bxs-truck"></i>
                    Crear Proveedor
                  </span>
                  </el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </quick-start-tip>
        <li
          v-if="!$store.state.account.membershipLimitReached"
          class="flex items-center"
        >
          <el-popover placement="bottom" width="500" popper-class="notifications-popper">
            <notifications-mini-list />
            <el-button
              slot="reference"
              type="text"
              class="w-full p-0 text-gray-500 cursor-pointer hover:bg-gray-200 rounded-md"
            >
              <span class="flex items-center p-2 space-x-2 relative">
                <i class="text-xl bx bx-bell"></i>
                <span
                  v-if="hasNotifications"
                  class="font-semibold absolute top-1 right-1"
                >
                  <i class="text-xs text-red-400 bx bxs-circle"></i>
                </span>
              </span>
            </el-button>
          </el-popover>
        </li>
        <li>
          <el-dropdown trigger="click">
            <div
              class="p-2 px-3 text-gray-700 rounded el-dropdown-link hover:bg-gray-200"
            >
              <span class="font-bold">
                {{ fullname }} <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="$store.state.account.accountType !== 1">
                <el-button
                  class="text-gray-700 hover:text-purple-500"
                  type="text"
                  @click="navigate('my-data')"
                >
                  <span class="flex items-center">
                    <i class="text-xl bx bxs-user-circle"></i>
                    Configurar mi cuenta
                  </span>
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item v-if="$store.state.account.accountType === 1">
                <el-button
                  type="text"
                  class="text-gray-700 hover:text-purple-500"
                  @click="navigate('my-business')"
                >
                  <span class="flex items-center">
                    <i class="text-xl bx bxs-cog"></i>
                    Administrar mi negocio
                  </span>
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item divided>
                <el-button
                  class="text-gray-700 hover:text-purple-500"
                  type="text"
                  @click="logout()"
                >
                  <span class="flex items-center">
                    <i class="text-xl bx bx-log-out-circle"></i>
                    Cerrar sesión
                  </span>
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div>
  </div>
</template>
<style>
.notifications-popper {
  z-index: 999 !important;
}
</style>
<script>
import SignService from "@/services/SignService";
import AdminService from "@/services/AdminService";
import OrderService from "@/services/OrderService";
import BusinessGetters from "@/store/modules/business/getters";
import { QUICK_START_STORE } from "@/store/modules/quick-start/mutation-types";

export default {
  name: "TheHeaderApp",
  props: {
    onMenuClick: Function,
  },
  components: {
    NotificationsMiniList: () => import("@/views/app/notifications/MiniList"),
  },
  data() {
    return {
      orders: [],
      clients: [],
      search: "",
      timeout: null,
    };
  },
  computed: {
    email() {
      return this.$store.state.account.email;
    },
    fullname() {
      return this.$store.state.account.fullname;
    },
    businessName() {
      return this.$store.state.account.businessName;
    },
    logo() {
      return BusinessGetters.logo();
    },
    hasNotifications() {
      return !!this.$store.state.notifications.entities.filter((notification) => !notification.read).length;
    },
  },
  methods: {
    logout() {
      SignService.logout();
      this.$router.push({ name: "landing.index" });
    },
    navigate(current) {
      const slot = this.$store.state.account.membershipLimitReached
        ? "my-membership"
        : current;
      this.$router.push({ name: "admin.index", params: { slot } });
    },
    blurSearch() {
      this.search = "";
      this.clients = [];
      this.orders = [];
    },
    async querySearchAsync(queryString) {
      if (queryString.length > 2) {
        const result = await AdminService.GlabalSearch.search(queryString);
        this.clients = result.clients;
        this.orders = result.orders;
      }
      if (queryString.length <= 2) {
        this.clients = [];
        this.orders = [];
      }
    },
    createFilter(queryString) {
      return (link) => {
        return (
          link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelectSearch(item, type) {
      let routeData = "";
      if (type === "order") {
        routeData = this.$router.resolve({
          name: "orders.read",
          params: { id: item.id },
        });
      }
      if (type === "client") {
        routeData = this.$router.resolve({
          name: "orders.index-filter",
          params: { clientid: item.id, client: item.fullName },
        });
      }
      this.blurSearch();
      window.open(routeData.href, "_blank");
    },
    async handleNextStep() {
      const step = this.$store.state.quickStart.step;
      await this.$store.dispatch(QUICK_START_STORE, { step: step + 1 });
      if (step === 1) {
        const quickActionsMenu = document.getElementById('quick-actions-menu');
        quickActionsMenu.click();
      }
      if (step === 2) {
        const { data: orders } = await OrderService.getAll({});
        if (!orders.length) {
          this.$toastr.e('No existen ordenes');
          return;
        }
        await this.$router.push({
          name: 'orders.read',
          params: { id: orders[0].id, lockScreen: true, lockScroll: true },
        });
      }
    },
  },
};
</script>
