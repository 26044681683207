<template>
  <app-layout>
    <div class="flex flex-col items-center py-4">
      <div class="w-full px-12">
        <div class="flex flex-row items-center py-4">
          <h1 class="text-lg font-bold">Órdenes <span v-if="client">de {{this.client}}</span></h1>
          <div class="flex flex-row ml-auto">
            <sa-button
              type="secondary"
              class="items-center py-3 font-bold rounded"
              @click="$router.push({
                name: 'admin.index',
                params: { slot: 'policies-and-templates', scrollToElementInSlot: 'orderStatusConfigRef' },
              })">
              Agregar Estatus
            </sa-button>
            <sa-button
              :disabled="!$store.state.account.canCreateOrders"
              type="primary"
              class="items-center px-3 py-3 font-bold rounded"
              @click="$router.push({ name: 'orders.create' })">
              Crear orden
            </sa-button>
          </div>
        </div>
        <div class="flex flex-col space-y-6">
          <content-card>
            <orders-list :is-mobile="isMobile" />
          </content-card>
        </div>
      </div>
    </div>
  </app-layout>
</template>
<script>
import AppLayout from "@/components/layouts/AppLayout.vue";
import ContentCard from "@/components/molecules/ContentCard.vue";
import OrdersList from "@/views/app/orders/OrdersList.vue";
import responsiveSize from '@/mixins/responsiveSize';

export default {
  name: "OrdersIndex",
  mixins: [responsiveSize],
  components: {
    AppLayout,
    ContentCard,
    OrdersList,
  },
  data() {
    return {
      query: {
        state: "Open",
      },
      client: false,
      isMobile: false,
    };
  },
  mounted() {
    const client = this.$route.params.client;
    if (client) {
      this.client = client;
    }
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.isMobile = percentageWidth === '90%';
    },
  }
};
</script>
