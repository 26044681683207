import _ from "lodash";
import { OrderStatusIds } from '@/constants';

const findRestrictedStatusById = (statusId) => (status) => status.id === statusId;

const findProtectedStatusByIdOrParentId = (statusId) => (status) => status.id === statusId
  || status.parentOrderStatusId === statusId;

const getRestrictedStatuses = (statuses = []) => {
  if (_.isEmpty(statuses)) {
    return {
      PendingAssignment: {},
      InProgress: {},
      ToBeDelivered: {},
      Finalized: {}
    };
  }

  return {
    PendingAssignment: statuses.find(findRestrictedStatusById(OrderStatusIds.PendingAssignment)),
    InProgress: statuses.find(findRestrictedStatusById(OrderStatusIds.InProgress)),
    ToBeDelivered: statuses.find(findRestrictedStatusById(OrderStatusIds.ToBeDelivered)),
    Finalized: statuses.find(findRestrictedStatusById(OrderStatusIds.Finalized)),
  };
};

const getProtectedStatuses = (statuses = []) => {
  if (_.isEmpty(statuses)) {
    return {
      Reject: {},
      NoSolution: {},
      Warranty: {}
    };
  }

  return {
    Reject: statuses.find(findProtectedStatusByIdOrParentId(OrderStatusIds.Reject)),
    NoSolution: statuses.find(findProtectedStatusByIdOrParentId(OrderStatusIds.NoSolution)),
    Warranty: statuses.find(findProtectedStatusByIdOrParentId(OrderStatusIds.Warranty)),
  };
};

const getCustomStatuses = (statuses = []) => {
  if (_.isEmpty(statuses)) {
    return [];
  }
  const {
    PendingAssignment,
    InProgress,
    ToBeDelivered,
    Finalized
  } = getRestrictedStatuses(statuses);

  const {
    Reject,
    NoSolution,
    Warranty
  } = getProtectedStatuses(statuses);

  const samiiStatuses = [
    _.get(PendingAssignment, 'id'),
    _.get(InProgress, 'id'),
    _.get(ToBeDelivered, 'id'),
    _.get(Finalized, 'id'),
    _.get(Reject, 'id'),
    _.get(NoSolution, 'id'),
    _.get(Warranty, 'id'),
  ];

  return statuses.filter((status) => {
    return !samiiStatuses.includes(status.id);
  })
};

export {
  getRestrictedStatuses,
  getProtectedStatuses,
  getCustomStatuses,
};
